export const SET_SEARCH_QUERY = 'SET_SEARCH_QUERY';
export const SET_COURSE_FILTER_PARAMS = 'SET_COURSE_FILTER_PARAMS';
export const RECEIVE_TERMS = 'RECEIVE_TERMS';

export const REQUEST_COURSE = 'REQUEST_COURSE';
export const RECEIVE_COURSE = 'RECEIVE_COURSE';
export const REQUEST_COURSE_SEARCH = 'REQUEST_COURSE_SEARCH';
export const RECEIVE_COURSE_SEARCH = 'RECEIVE_COURSE_SEARCH';

export const REQUEST_COURSE_GRADES = 'REQUEST_COURSE_GRADES';
export const RECEIVE_COURSE_GRADES = 'RECEIVE_COURSE_GRADES';

export const REQUEST_INSTRUCTOR = 'REQUEST_INSTRUCTOR';
export const RECEIVE_INSTRUCTOR = 'RECEIVE_INSTRUCTOR';
export const REQUEST_INSTRUCTOR_SEARCH = 'REQUEST_INSTRUCTOR_SEARCH';
export const RECEIVE_INSTRUCTOR_SEARCH = 'RECEIVE_INSTRUCTOR_SEARCH';
export const REQUEST_INSTRUCTOR_GRADES = 'REQUEST_INSTRUCTOR_GRADES';
export const RECEIVE_INSTRUCTOR_GRADES = 'RECEIVE_INSTRUCTOR_GRADES';

export const REQUEST_SUBJECT = 'REQUEST_SUBJECT';
export const RECEIVE_SUBJECT = 'RECEIVE_SUBJECT';
export const REQUEST_SUBJECT_SEARCH = 'REQUEST_SUBJECT_SEARCH';
export const RECEIVE_SUBJECT_SEARCH = 'RECEIVE_SUBJECT_SEARCH';

export const REQUEST_EXPLORE_COURSES = 'REQUEST_EXPLORE_COURSES';
export const RECEIVE_EXPLORE_COURSES = 'RECEIVE_EXPLORE_COURSES';
export const REQUEST_EXPLORE_INSTRUCTORS = 'REQUEST_EXPLORE_INSTRUCTORS';
export const RECEIVE_EXPLORE_INSTRUCTORS = 'RECEIVE_EXPLORE_INSTRUCTORS';
export const REQUEST_EXPLORE_SUBJECTS = 'REQUEST_EXPLORE_SUBJECTS';
export const RECEIVE_EXPLORE_SUBJECTS = 'RECEIVE_EXPLORE_SUBJECTS';